import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import { Delivery } from "../../Models";

import api from "../Api";

export const CONSTANTS = {
  FETCH_BY_ID: "FETCH_BY_ID",
  FETCH_ONE_COMPLETED: "FETCH_ONE_COMPLETED",
};

const INITIAL_STATE = {
  item: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_BY_ID:
      return {
        ...state,
        item: action.item,
      };
    case CONSTANTS.FETCH_ONE_COMPLETED:
      return {
        ...state,
        item: action.item,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveryStore = () => {
  const { dispatch, state } = useContext(Context);

  const getDelivery = useCallback(
    async ({ bookId }) => {
      const delivery = await api.get(`/public/deliveries/${bookId}`);

      dispatch({
        type: CONSTANTS.FETCH_BY_ID,
        item: new Delivery(delivery),
      });
    },
    [dispatch],
  );

  const getCompleted = useCallback(
    async ({ token }) => {
      const delivery = await api.get(
        `/public/deliveries/completed`,
        {},
        {
          "x-auth-token": token,
        },
      );

      dispatch({
        type: CONSTANTS.FETCH_ONE_COMPLETED,
        item: new Delivery(delivery),
      });
    },
    [dispatch],
  );

  return {
    state,
    getDelivery,
    getCompleted,
  };
};
